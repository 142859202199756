import { createRoot } from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';

import apolloClient from './apolloClient';
import reduxStore from './reduxStore';
import App from './App';

import './index.css';

const container = document.getElementById('root');
if (!container) throw new Error('Root element not found!');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

// TODO: Add StrictMode - check https://github.com/reactjs/react-transition-group/issues/820
//                        before migrating
root.render(
  <Provider store={reduxStore}>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </Provider>,
);
