import * as React from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import { Container } from './containers/StartPage/Layout';
import { CenterBox } from './components';
import hero from './img/hero_bg.jpg';

function AppLoading() {
  return <Container id="startpage-container" style={{ backgroundImage: `url(${hero})` }}>
    <CenterBox>
      <ThreeBounce color="#666" /> setting up environment.
    </CenterBox>
  </Container>
}

export default AppLoading;
