import { getChoiceFromGroup } from './getChoices2Show';
import { AddChoice, BuildDisplayProps, DisplayChoice, NoAdd } from './types';

let lastMainChoiceName: string[] = [];
const addToMainChoiceList = (name: string, superChoiceName?: string): void => {
  // Handle roman numbers
  const fixedName = name.replace(/ (I{1,3}|I[VX]|[VX]|[VX]I{1,3})$/, '');

  lastMainChoiceName.reverse();
  const idx = lastMainChoiceName.findIndex((haystack) => fixedName.startsWith(haystack));
  if (idx < 0) {
    lastMainChoiceName = [superChoiceName, fixedName].filter((c): c is string => !!c);
    return;
  }
  // Return to original order
  lastMainChoiceName.reverse();

  // Drop earlier elements no longer matching
  // e.g.
  // THR left
  // THR left cup
  // THR left cup wear
  //
  // when encountering "THR left stem" should generate
  // THR left
  // THR left stem
  if (idx > 0) {
    lastMainChoiceName = lastMainChoiceName.slice(0, lastMainChoiceName.length - idx);
  }

  // Append to end the element that we're matching
  lastMainChoiceName.push(fixedName);
};

export const getChoices2Display = ({
  isSelected,
  getChoiceGroup,
  superChoiceName,
}: Pick<BuildDisplayProps, 'superChoiceName' | 'isSelected' | 'getChoiceGroup'>) => {
  const setMainChoiceName = (name: string) => addToMainChoiceList(name, superChoiceName);
  setMainChoiceName('');

  let lastGroup: string = '@@init@@_@@value@@';
  const setLastGroup = (name: string) => {
    lastGroup = name;
  };

  return (choice: DisplayChoice): NoAdd | AddChoice => {
    const {
      short_description: shortDescription,
      detailed_description: detailedDescription = 'There are *no details* specified for this option',
    } = choice;

    const grp = getChoiceGroup(choice);
    if (!grp) {
      setMainChoiceName(choice.displayName);
      setLastGroup('__undefined__');
      return {
        addChoice2Grp: true,
        collapseGroup: false,
        keyName: '__undefined__',
        shortDescription,
        displayName: choice.displayName,
        detailedDescription,
        lastGroup,
      } as AddChoice;
    }

    return getChoiceFromGroup({
      choice,
      grp,
      isSelected,
      lastMainChoiceName,
      lastGroup,
      setMainChoiceName,
      setLastGroup,
    });
  };
};
