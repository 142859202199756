/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import { produce } from 'immer';
import {
  SET_REPORT_SIMPLE,
  TOGGLE_REPORT_SIMPLE,
  SetReportSimpleAction,
  ToggleReportSimpleAction,
} from '../actions/types';
import { ReportState } from './types';

const initialReportState = (): ReportState => ({ simple: false });
export const report: Reducer<ReportState, SetReportSimpleAction | ToggleReportSimpleAction> = (
  state: ReportState | undefined,
  args: SetReportSimpleAction | ToggleReportSimpleAction,
): ReportState =>
  produce<ReportState>(state || initialReportState(), (draft) => {
    switch (args.type) {
      case SET_REPORT_SIMPLE: {
        const { simple } = args;

        draft.simple = simple;
        break;
      }
      case TOGGLE_REPORT_SIMPLE: {
        draft.simple = !draft.simple;
        break;
      }
      default:
    }

    return draft;
  });
