import { lazy, Suspense, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'regenerator-runtime/runtime';
import { get } from 'lodash-es';
import { ThemeProvider } from 'styled-components';

import { AuthenticateRoute } from './components';
import AppLoading from './AppLoading';
import AppError from './AppError';

import './App.css';
import './style/style.css';
import { useCurrentUser, retrieveTaskData, CrntUsrCtxt } from './gql/useCurrentUser';
import themes from './themes';
import { CurrentUserType } from './gql/fragments';

const StartPage = lazy(() => import(/* webpackChunkName: "StartPage" */ './containers/StartPage'));
const LabelController = lazy(() => import(/* webpackChunkName: "Labeling" */ './containers/Label'));
const DemoController = lazy(() => import(/* webpackChunkName: "Demo" */ './containers/Demo'));
const PacsController = lazy(() => import(/* webpackChunkName: "PACS" */ './containers/PACS'));

function App() {
  const userQuery = useCurrentUser({ fetchPolicy: 'network-only' });

  const current: CurrentUserType | undefined = get(userQuery, 'data.user.current');

  const ctxtValues = useMemo(
    () => ({
      ...userQuery,
      current,
      current_task: retrieveTaskData(userQuery),
      siths: get(userQuery, 'data.user.SITHS'),
    }),
    [current, userQuery],
  );

  if (userQuery.loading) return <AppLoading />;
  if (userQuery.error) return <AppError />;

  let usrTheme = themes.Batmode;
  if (current) {
    const { theme_name: tm } = current.worker.settings.display;
    if (tm && tm in themes) {
      usrTheme = themes[tm];
    }
  }

  return (
    <Router>
      <CrntUsrCtxt.Provider value={ctxtValues}>
        <ThemeProvider theme={usrTheme}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL || ''}/label/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AuthenticateRoute roles2allow={['user']}>
                    <LabelController />
                  </AuthenticateRoute>
                </Suspense>
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL || ''}/demo/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AuthenticateRoute roles2allow={['demo']}>
                    <DemoController />
                  </AuthenticateRoute>
                </Suspense>
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL || ''}/pacs/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <AuthenticateRoute roles2allow={['dr']}>
                    <PacsController />
                  </AuthenticateRoute>
                </Suspense>
              }
            />
            <Route
              path={`${process.env.PUBLIC_URL || ''}/*`}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <StartPage />
                </Suspense>
              }
            />
          </Routes>
        </ThemeProvider>
      </CrntUsrCtxt.Provider>
    </Router>
  );
}

export default App;
