import { produce } from 'immer';
import ImgMrkr, { Props as ImgProps, CoreData as ImgCoreData } from './ImgMrkr';
import Line, { CoreData as LineCoreData, Props as LineProps } from './Line';
import { getMappedCoords, roundCoords, purePosition } from './helpers';
import { XYpos } from './types/XYpos';
import { SuggestedIndependentLine } from '../../types';
import { DrawArguments, GetPointsArguments } from './types/general';
import getAngle from './helpers/getAngle';
import getTextMidPosition from './helpers/getTextMidPosition';

interface IndependentLineSubmission {
  line1: {
    c1: XYpos;
    c2: XYpos;
  };
  line2: {
    c1: XYpos;
    c2: XYpos;
  };
  short_description: string;
  image: string;
}

export interface CoreData extends ImgCoreData {
  type: 'IndependentLine';
  line1: LineCoreData;
  line2: LineCoreData;
}

const initLine = (data: Line | LineProps | undefined, name: string, props: LineProps) => {
  if (data instanceof Line) {
    return data;
  }

  if (data) {
    return new Line(data);
  }

  return new Line({ ...props, drawLength: false, short_description: name });
};

export interface Props extends ImgProps {
  line1?: LineCoreData | Line;
  line2?: LineCoreData | Line;
}
export default class IndependentLine extends ImgMrkr<Props, CoreData> {
  line1: Line;

  line2: Line;

  constructor(props: Props) {
    const { line1, line2, ...superProps } = props;
    super(props, 'IndependentLine');

    const lineProps: LineProps = {
      ...superProps,
    };

    lineProps.options = {
      ruler: 'none',
      ...lineProps.options,
    };

    this.line1 = initLine(line1, 'first line', lineProps);
    this.line2 = initLine(line2, 'second line', lineProps);
  }

  serialize() {
    return {
      ...this.data,
      line1: this.line1.serialize(),
      line2: this.line2.serialize(),
    };
  }

  getSubmissionObject(): IndependentLineSubmission | null {
    if (this.requireDone('independent line') === null) {
      return null;
    }

    if (!this.line1 || !this.line2)
      throw new Error('The line1 & line2 must be set before preparing submission object');
    const [line1C1, line1C2, line2C1, line2C2] = this.getPoints({ useMovingPoint: false });
    if (!line1C1 || !line1C2 || !line2C1 || !line2C2) {
      throw new Error("The lines aren't done before preparing submission object");
    }
    return {
      ...super.getImgSubmissionObject(),
      line1: { c1: line1C1, c2: line1C2 },
      line2: { c1: line2C1, c2: line2C2 },
    };
  }

  setSuggested(vals: SuggestedIndependentLine): IndependentLine {
    super.setSuggested(vals);
    this.setPoint(purePosition(vals.line1.c1), 0, vals.image.id);
    this.setPoint(purePosition(vals.line1.c2), 1, vals.image.id);
    this.setPoint(purePosition(vals.line2.c1), 2, vals.image.id);
    this.setPoint(purePosition(vals.line2.c2), 3, vals.image.id);
    // Thre is a risk of setPoint erasing the suggested status
    const setSuggested = produce((draft: LineCoreData) => {
      draft.suggested = true;
    });
    this.line1.data = setSuggested(this.line1.data);
    this.line2.data = setSuggested(this.line2.data);
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = true;
    });
    return this.clone();
  }

  isDone(): boolean {
    if (!super.isDone()) return false;

    if (!this.line1.isDone()) return false;
    if (!this.line2.isDone()) return false;

    return true;
  }

  getIndex(point: XYpos): number {
    let index: number = this.line1.getIndex(point);
    if (index < 0) {
      index = this.line2.getIndex(point);
      if (index < 0) {
        return super.getIndex(point);
      }
      index += 2;
    }

    return index;
  }

  setPoint(point: XYpos, index: number | undefined, imgId: string): IndependentLine {
    if (!this.image) this.setImage(imgId);
    if (this.image !== imgId) return this;
    this.data = produce<CoreData>(this.data, (draft) => {
      draft.suggested = false; // This class is special as the lines are handling most stuff

      if (!this.isDone()) {
        if (!this.line1.isDone()) {
          this.line1 = this.line1.setPoint(point, undefined, imgId);
        } else {
          this.line2 = this.line2.setPoint(point, undefined, imgId);
        }
        return;
      }

      if (index === undefined) return;

      if (index < 2) {
        this.line1 = this.line1.setPoint(point, index, imgId);
      } else if (index < 4) {
        this.line2 = this.line2.setPoint(point, index - 2, imgId);
      } else {
        throw new Error('The index has to be between 0 and 3');
      }
    });

    return this.clone();
  }

  setMovingPoint(point: XYpos, index: number | undefined, imgId: string) {
    if (this.image === imgId) {
      if (index !== undefined) {
        if (index < 2) {
          this.line1.setMovingPoint(point, index, imgId);
        } else {
          this.line2.setMovingPoint(point, index - 2, imgId);
        }
      } else if (!this.isDone()) {
        if (!this.line1.isDone()) {
          this.line1.setMovingPoint(point, index, imgId);
        } else {
          this.line2.setMovingPoint(point, index, imgId);
        }
      }
    } else {
      this.unSetMovingPoint();
    }
  }

  unSetMovingPoint() {
    this.line1.unSetMovingPoint();
    this.line2.unSetMovingPoint();
  }

  drawBridgeLine(ctxt: CanvasRenderingContext2D, width: number, height: number) {
    const m1 = this.line1.midPoint();
    const m2 = this.line2.midPoint();
    if (!m1 || !m2) return;

    const { x: x1, y: y1 } = roundCoords(getMappedCoords(m1, width, height));
    const { x: x2, y: y2 } = roundCoords(getMappedCoords(m2, width, height));

    const strokeStyle = '#FFFFFF'; // eslint-disable-line
    ctxt.strokeStyle = strokeStyle; // eslint-disable-line
    ctxt.lineJoin = ctxt.lineCap = 'round'; // eslint-disable-line
    ctxt.shadowColor = '#555555'; // eslint-disable-line
    ctxt.shadowBlur = 2; // eslint-disable-line

    ctxt.beginPath();
    ctxt.moveTo(x1, y1);
    ctxt.lineTo(x2, y2);
    ctxt.fill();
    ctxt.stroke();
    ctxt.closePath();

    ctxt.shadowColor = ''; // eslint-disable-line
    ctxt.shadowBlur = 0; // eslint-disable-line
  }

  writeAngleTxt(ctxt: CanvasRenderingContext2D, width: number, height: number) {
    const m1 = this.line1.midPoint();
    const m2 = this.line2.midPoint();
    const angle = this.angle(false, width, height);
    if (!m1 || !m2 || !angle) return;

    const midMidline = {
      x: m1.x - (m1.x - m2.x) / 2,
      y: m1.y - (m1.y - m2.y) / 2,
    };

    const textCenter = roundCoords(getMappedCoords(midMidline, width, height));
    ctxt.strokeStyle = 'white'; // eslint-disable-line

    const { smallAngle, largeAngle } = getAngle(angle);
    const angleTxt = `${smallAngle.toFixed(1)} (${[largeAngle.toFixed(1)]})`;
    const { xpos, align } = getTextMidPosition({ xCenter: textCenter.x, canvasPixelWidth: width });
    if (align) {
      // eslint-disable-next-line no-param-reassign
      ctxt.textAlign = align;
    }
    ctxt.strokeText(angleTxt, xpos, textCenter.y);
  }

  draw(args: DrawArguments) {
    const { ctxt, width, height } = args;
    args.strokeStyle = this.getStrokeStyle(args.strokeStyle); // eslint-disable-line

    this.drawBridgeLine(ctxt, width, height);
    this.writeAngleTxt(ctxt, width, height);
    this.line1.draw(args);
    this.line2.draw(args);
  }

  getPoints(args: GetPointsArguments = {}): (XYpos | undefined)[] {
    return [...this.line1.getPoints(args), ...this.line2.getPoints(args)];
  }

  countUnsetPoints(): number {
    // We only provide one line at the time
    if (!this.line1.isDone()) return this.line1.countUnsetPoints();

    return this.line2.countUnsetPoints();
  }

  angle(radians: boolean | undefined, width: number, height: number): number | undefined {
    const angle1 = this.line1.angle(true, width, height);
    const angle2 = this.line2.angle(true, width, height);
    if (!angle1 || !angle2) return undefined;

    let angle = angle2 - angle1;
    if (angle > Math.PI) {
      angle = angle - Math.PI * 1.5 - Math.PI / 2;
    }

    if (!radians) {
      return (angle / Math.PI) * 180;
    }
    return angle;
  }
}
