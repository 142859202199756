import { BuildDisplayProps, DisplayChoice } from './types';

/**
 * If all subchoices start on the same prefix we want to remove that prefix
 * as if it is a shared stem
 */
export const dropSuperChoiceNameFromDisplayName = ({
  superChoiceName,
  rawChoices,
}: { rawChoices: DisplayChoice[] } & Pick<
  BuildDisplayProps,
  'superChoiceName'
>): DisplayChoice[] => {
  if (!superChoiceName) return rawChoices;

  if (
    rawChoices.find(
      (c) => c.short_description.substr(0, superChoiceName.length) !== superChoiceName,
    )
  ) {
    return rawChoices;
  }

  return rawChoices.map((c) => ({
    ...c,
    displayName: c.displayName.substr(superChoiceName.length).trim(),
  }));
};
