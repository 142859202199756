import { Groups } from '../getGroups';
import { AddChoice, BuildDisplayProps, DisplayChoice, NoAdd } from './types';

interface ChoiceFromGroup extends Pick<BuildDisplayProps, 'isSelected'> {
  choice: DisplayChoice;
  grp: Groups;
  lastMainChoiceName: string[];
  lastGroup: string;
  setMainChoiceName: (name: string) => void;
  setLastGroup: (name: string) => void;
}

export const getChoiceFromGroup = ({
  choice,
  grp,
  isSelected,
  lastMainChoiceName,
  lastGroup,
  setMainChoiceName,
  setLastGroup,
}: ChoiceFromGroup): NoAdd | AddChoice => {
  let { displayName } = choice;
  const {
    short_description: shortDescription,
    detailed_description: detailedDescription = 'There are *no details* specified for this option',
  } = choice;
  const { collapse, max, members, shared_name_stem: shrdStem } = grp;
  let { name: keyName } = grp;

  const selectedMembers = members.filter((m) => isSelected(m.short_description));
  const collapseGroup = !!(collapse && max && max <= selectedMembers.length);
  const selected = isSelected(choice.short_description);
  if (!selected && collapseGroup) {
    return { addChoice2Grp: false, collapseGroup, keyName, group: grp };
  }

  if (lastMainChoiceName.length > 0 && shrdStem) {
    const lm = [...lastMainChoiceName];
    lm.reverse();
    let mainChoice = shortDescription;
    let largestExistingStem = lm.find((c) => shortDescription.startsWith(c));
    if (largestExistingStem) {
      displayName = shortDescription.substr(largestExistingStem.length).trim();
    } else {
      largestExistingStem = lm.find((c) => displayName.startsWith(c));
      if (largestExistingStem) {
        mainChoice = displayName;
        displayName = displayName.substr(largestExistingStem.length).trim();
      }
    }

    const lc = lastMainChoiceName[lastMainChoiceName.length - 1] || '';
    const startLength = lc.length;
    let hasSharedStem = lastGroup !== keyName;
    if (hasSharedStem) {
      let matchingDesc = displayName.substr(0, startLength);
      // qualifier match
      matchingDesc = matchingDesc.replace(/[_¤]+$/, '');
      hasSharedStem = lc.substr(0, matchingDesc.length) === matchingDesc;
    }

    if (selected && collapseGroup) {
      if (hasSharedStem) {
        displayName = displayName.replace(/^[_¤]+/, '');
        keyName = lastGroup;
      }
    } else if (largestExistingStem) {
      displayName = `...${displayName.replace(/^[_¤]+/, '')}`;
      keyName = lastGroup;
    }

    setMainChoiceName(mainChoice);
  } else {
    setMainChoiceName(choice.displayName);
  }

  setLastGroup(keyName);
  return {
    addChoice2Grp: true,
    collapseGroup,
    keyName,
    shortDescription,
    displayName,
    detailedDescription,
    lastWithShared: lastMainChoiceName[0],
    group: grp,
    lastGroup,
  };
};
