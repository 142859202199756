/* eslint-disable no-param-reassign */
import { Reducer } from 'redux';
import {
  SET_ANONYMIZE,
  SET_BURGER_MENU_OPEN,
  SET_HEADER,
  SetAnonymizeAction,
  SetBurgerOpenAction,
  SetHeaderAction,
} from '../actions/types';
import { HeaderState } from './types';

const initialHeaderState = () => ({ txt: '', anonymize: false, burgerMenuIsOpen: false });
export const header: Reducer<HeaderState, any> = (
  state: HeaderState | undefined,
  args: SetAnonymizeAction | SetHeaderAction | SetBurgerOpenAction,
): HeaderState => {
  if (!state) {
    state = initialHeaderState();
  }

  switch (args.type) {
    case SET_HEADER: {
      const { txt } = args;

      // Note: avoid immer here as this sets the object to immutable and React mutates React objects
      // during development mode
      state = { ...state, txt };
      break;
    }
    case SET_ANONYMIZE: {
      const { anonymize } = args;

      state.anonymize = !!anonymize;
      break;
    }
    case SET_BURGER_MENU_OPEN: {
      const { open } = args;
      state.burgerMenuIsOpen = open;
      break;
    }
    default:
  }

  return state;
};
