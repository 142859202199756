import { ReducersMapObject } from 'redux';
import { labels } from './labels';
import { header } from './header';
import { images } from './images';
import { report } from './report';
import { toolBar } from './toolBar';
import { work } from './work';
import {
  ReportState,
  HeaderState,
  ImagesState,
  LabelState,
  WorkState,
  ToolBarState,
} from './types';

export interface State {
  header: HeaderState;
  images: ImagesState;
  labels: LabelState;
  report: ReportState;
  work: WorkState;
  toolBar: ToolBarState;
}

// We combine the reducers here so that they can be left split apart above
const reducerMap: ReducersMapObject = {
  header, // The text that the NavBar header should have during labeling
  images, // The images - selection, storage etc
  report, // Stuff related to the report
  labels, // Tracks all the labels that the user has set
  toolBar, // Toolbar stuff, currently primarily the maximized status
  work, // Handles the loaded campaign work for each of the campaigns
};

export default reducerMap;
