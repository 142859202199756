import * as React from 'react';

interface Props {
  errorMessage?: string | undefined;
  children: React.ReactNode;
  tag?: React.ComponentType<{ children: string }> | string;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: unknown) {
    // Display fallback UI
    this.setState({ hasError: true });

    // eslint-disable-next-line no-console
    console.warn(error, info);
  }

  render() {
    const { hasError } = this.state;
    const {
      errorMessage = 'Ooops',
      children,
      tag, // A <tag></tag> won't work as these are literal elements
    } = this.props;

    const Tag = tag || 'h1';
    if (hasError) {
      if (!errorMessage) return null;

      return <Tag>{errorMessage}</Tag>;
    }

    return children;
  }
}
