/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { UncontrolledTooltipProps, Badge, BadgeProps } from 'reactstrap';
import Tooltip from './Tooltip';

interface Props extends BadgeProps {
  color?: string;
  tip: string;
  id: string;
  children: React.ReactNode | string | number;
  placement?: UncontrolledTooltipProps['placement'];
}

let counter = 0;

function TooltipBadge(props: Props) {
  const [counterId, setCounterId] = React.useState(-1);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const [activeTip, setActiveTip] = React.useState(false);

  React.useEffect(() => {
    counter += 1;
    setCounterId(counter);
    setActiveTip(true);
    return () => {
      setActiveTip(false);
    };
  }, [setCounterId]);

  const toggle = React.useCallback(() => {
    if (!activeTip) return;
    setTooltipOpen(!tooltipOpen);
  }, [activeTip, setTooltipOpen, tooltipOpen]);

  const { color = 'light', id, children, tip, placement = 'bottom', ...other } = props;
  const componentId = `Tooltip_${id.replace(/\W/g, '_')}_${counterId}`;

  return (
    <Tooltip
      tipId={componentId}
      tip={tip}
      placement={placement}
      isOpen={tooltipOpen}
      target={componentId}
      toggle={toggle}
    >
      <Badge color={color} id={componentId} {...other}>
        {children}
      </Badge>
    </Tooltip>
  );
}

export default React.memo(TooltipBadge);
