import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import { filterXSS, IFilterXSSOptions, getDefaultWhiteList } from 'xss';

interface Props {
  value?: string;
}

function FormattedText(props: Props) {
  const { value } = props;
  if (typeof value !== 'string') return null;

  if (value.indexOf('<') === 0) {
    const options: IFilterXSSOptions = {
      whiteList: {
        ...getDefaultWhiteList(),
        ...{
          source: ['src', 'type'],
          img: ['src', 'alt', 'title', 'width', 'height', 'style'],
          video: ['autoplay', 'controls', 'loop', 'preload', 'src', 'height', 'width', 'style'],
        },
      },
      stripIgnoreTagBody: ['script'], // the script tag is a special case, we need
      // to filter out its content
    };
    const filteredContent = filterXSS(value, options);
    return <div dangerouslySetInnerHTML={{ __html: filteredContent }} />; // eslint-disable-line
  }

  return <ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{value}</ReactMarkdown>;
}

export default React.memo(FormattedText);
