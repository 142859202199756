import { Navigate } from 'react-router-dom';
import { useCurrentUserCtxt } from '../gql/useCurrentUser';
import { Roles } from '../types';

interface Props {
  roles2allow: Roles[];
  children: JSX.Element;
}

export function AuthenticateRoute({ roles2allow, children }: Props): JSX.Element {
  const { user, loginPath } = useCurrentUserCtxt();

  if (!user) {
    return <Navigate to={loginPath} />;
  }

  if (!user.roles.find((r) => roles2allow.includes(r))) {
    return <Navigate to={`${process.env.PUBLIC_URL || ''}/unauthorized`} />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return children;
}
