import { Groups } from '../getGroups';
import { ChoiceGroups } from '../types';
import { convertMrkrDisplayChoice } from './convertMrkrDisplayChoice';
import { dropSuperChoiceNameFromDisplayName } from './dropSuperChoiceNameFromDisplayName';
import { getChoices2Display } from './getChoices2Display';
import { BuildDisplayProps } from './types';

const shouldWeAddNewGroup = ({
  lastGroup,
  keyName,
  choiceGroups,
  group,
}: {
  lastGroup: string;
  keyName: string;
  choiceGroups: ChoiceGroups[];
  group?: Groups;
}): boolean =>
  keyName !== lastGroup &&
  (choiceGroups.length === 0 ||
    !choiceGroups[choiceGroups.length - 1].choices.find(
      (c) => group && group.activating_short_descriptions.includes(c.short_description),
    ));

export const buildChoices2display = ({
  choices,
  superChoiceName,
  isSelected,
  getChoiceGroup,
}: BuildDisplayProps): ChoiceGroups[] => {
  let rawChoices = choices.map(convertMrkrDisplayChoice);
  rawChoices = dropSuperChoiceNameFromDisplayName({ rawChoices, superChoiceName });

  const groupNameCount: { [key: string]: number } = {};
  const choiceGroups: ChoiceGroups[] = [];

  const processedChoices = rawChoices.map(getChoices2Display({ isSelected, getChoiceGroup }));

  processedChoices.forEach(({ group, collapseGroup, keyName, ...rest }) => {
    if (rest.addChoice2Grp) {
      const { detailedDescription, displayName, lastGroup, shortDescription, lastWithShared } =
        rest;

      if (
        choiceGroups.length === 0 ||
        shouldWeAddNewGroup({ lastGroup, keyName, choiceGroups, group })
      ) {
        if (keyName in groupNameCount) {
          groupNameCount[keyName] += 1;
        } else {
          groupNameCount[keyName] = 0;
        }

        const suffixCounter = `_${groupNameCount[keyName]}` || '';
        choiceGroups.push({
          name: keyName,
          key: `${keyName}${suffixCounter}`,
          choices: [],
        });
      }
      const choice = {
        selected: isSelected(shortDescription),
        short_description: shortDescription,
        detailed_description: `${displayName}\n\n${detailedDescription}`,
        display_name: displayName.replace(/^[_¤]+/, '').replace(/^.+¤/, ''),
        lastWithShared,
      };
      choiceGroups[choiceGroups.length - 1].choices.push(choice);
    }

    // If the group is collapsed then only the selected choices should be shown
    if (collapseGroup && group) {
      const choiceGroup = choiceGroups.find(({ name }) => name === keyName);
      if (choiceGroup) {
        choiceGroup.choices = choiceGroup.choices.filter(
          ({ short_description: s }) =>
            !group.members.find((m) => m.short_description === s) || isSelected(s),
        );
      }
    }
  });

  return choiceGroups;
};
