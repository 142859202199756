import { Button, ButtonDropdown } from 'reactstrap';
import styled from 'styled-components/macro';

export const StyledButton = styled(Button)`
  width: ${({ width }) => (!width ? 'inherit' : `${width}px`)};
  font-size: 0.8rem;
  padding: 0.2625rem 0.525rem;

  @media screen and (max-width: 800px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.5)}px`)};
    font-size: 0.5rem;
    padding: 0.1875rem 0.375rem;
  }
  @media screen and (max-height: 1000px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.5)}px`)};
    font-size: 0.5rem;
    padding: 0.1875rem 0.375rem;
  }
  @media screen and (max-width: 1000px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.7)}px`)};
    font-size: 0.7rem;
    padding: 0.2625rem 0.525rem;
  }
  @media screen and (max-height: 1000px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.7)}px`)};
    font-size: 0.7rem;
    padding: 0.2625rem 0.525rem;
  }
`;

export const StyledButtonDropdown = styled(ButtonDropdown)`
  width: ${({ width }) => (!width ? 'inherit' : `${width}px`)};
  & > .btn {
    font-size: 0.8rem;
    padding: 0.2625rem 0.525rem;
  }

  @media screen and (max-width: 800px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.5)}px`)};
    & > .btn {
      font-size: 0.5rem;
      padding: 0.1875rem 0.375rem;
    }
  }
  @media screen and (max-height: 800px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.5)}px`)};
    & > .btn {
      font-size: 0.5rem;
      padding: 0.1875rem 0.375rem;
    }
  }
  @media screen and (max-width: 1000px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.7)}px`)};
    & > .btn {
      font-size: 0.7rem;
      padding: 0.2625rem 0.525rem;
    }
  }
  @media screen and (max-height: 1000px) {
    width: ${({ width }) => (!width ? 'inherit' : `${Math.floor(width * 0.7)}px`)};
    & > .btn {
      font-size: 0.7rem;
      padding: 0.2625rem 0.525rem;
    }
  }
`;
